<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import navMenuItems from "@/navigation/vertical";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    //Bookmarks,
    SearchBar,
    DarkToggler,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      allRoles: [],
    };
  },
  async created() {
    if (!this.$store.state.menus) {
      console.log("not");
      this.$store.commit("setMenu", []);
    }
    if (this.$store.state.menus.length == 0) {
      console.log(this.$store.state.menus);
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Users/LoadRoles",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      await axios(config)
        .then((response) => {
          // console.log("res", response.data);
          this.allRoles = response.data.data;
          // console.log(this.allRoles);
        })
        .catch(function(error) {
          console.log(error);
        });

      var config2 = {
        method: "get",
        url:
          "https://api.geotalent.co/api/users/LoadRights?uID=" +
          this.$store.state.userData.userID,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config2).then((response) => {
        // console.log(response);
        if (response.data.status === "success") {
          this.$store.commit("setRights", response.data.data);
          // console.log(this.$store.state.rights);
          let data = [];
          this.allRoles.forEach((el) => {
            if (this.$store.state.rights.includes(el.id)) {
              let item = navMenuItems.find(
                (nav) => nav.title.toLowerCase() == el.name.toLowerCase()
              );
              if (item) data.push(item);
            }
          });
          console.log(data);
          this.$store.commit("setMenu", data);
          // console.log(this.$store.state.menus);
        }
      });
    }
  },
};
</script>
